export default {
  main_img_url:
    'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/news-main.jpg',
  topBlock: [
    {
      id: 20001,
      link: '/newsDetail',
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/news-top-01.jpg',
      title:
        '36氪首发｜聚焦睡眠、办公健康智能产品，「贝氪科技」获6000万元A轮融资',
      author: '36氪',
      date: '2022-09-08 13:50:55'
    },
    {
      id: 20002,
      link: '/newsDetail',
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/news-top-02.jpg',
      title: '热烈祝贺nitetronic添眠斩获“2022中国家电创新零售优秀案例奖”！',
      author: '添眠',
      date: '2022-09-08 13:17:05'
    },
    {
      id: 20003,
      link: '/newsDetail',
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/news-top-03.jpg',
      title:
        '热烈祝贺添眠™实验室成立大会暨第一届睡眠健康与科技研讨会在上海顺利召开！',
      author: '添眠',
      date: '2022-09-06 16:17:49'
    }
  ],
  videosList: [
    {
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/news-poster-01.png',
      link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/video-01.mp4'
    },
    {
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/news-poster-02.jpg',
      link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/video-02.mp4'
    },
    {
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/news-poster-03.jpg',
      link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/video-03.mp4'
    },
    {
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/news-poster-04.png',
      link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/videos/video-04.mp4'
    }
  ],
  newsList: {
    total: 10,
    list: [
      {
        id: 10001,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-01.jpg',
        title: '为什么你高价买的人体工学椅无法有效托腰，坐久了之后还是腰痛？',
        author: 'backrobo',
        date: '2021-04-27'
      },
      {
        id: 10002,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-02.jpg',
        title: '压力大、焦虑让人睡不着?如何释放身体中的「小情绪」睡个好觉?',
        author: 'blupeace',
        date: '2021-03-05'
      },
      {
        id: 10003,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-03.jpg',
        title:
          '经常熬夜还会变胖变丑！中国睡眠研究会调查报告：显示我国超3亿人存在睡眠障碍！',
        author: '贝氪',
        date: '2021-02-07'
      },
      {
        id: 10004,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-04.jpg',
        title: '最新睡眠时间表出炉：何时睡，睡多久，孩子才会更健康？',
        author: '',
        date: '2020-12-16 15:08:04'
      },
      {
        id: 10005,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-05.jpg',
        title: '精致生活｜起床的仪式感，让你告别诈尸式起床',
        author: '小贝',
        date: '2020-12-03 12:27:49'
      },
      {
        id: 10006,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-06.jpg',
        title: '舒睡小贴士｜90后睡眠困难户，掌握这些诀窍，还你一夜好眠',
        author: '小贝',
        date: '2020-11-28 12:42:26'
      },
      {
        id: 10007,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-07.jpg',
        title: '关注睡眠质量，失眠远不止睡不着那么简单！',
        author: '',
        date: '2020-11-26 17:03:17'
      },
      {
        id: 10008,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-08.jpg',
        title: '挺胸抬头坐端正毁了无数人的腰，这样坐才是最舒适健康的坐姿！',
        author: '小贝',
        date: '2020-11-24 10:46:41'
      },
      {
        id: 10009,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-09.jpg',
        title: '失眠，是因为你从不能放过自己',
        author: '贝氪',
        date: '2020-11-17 20:49:34'
      },
      {
        id: 10010,
        link: '/newsDetail',
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/news/newslist-10.jpg',
        title: '舒睡小贴士｜晚上睡不着，白天睡不醒！夜猫子你还好吗？',
        author: '小贝',
        date: '2020-11-08 16:36:13'
      }
    ]
  }
}
