export default {
  banner: {
    pc: [
      {
        id: 1002,
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/9.jpg',
        link: 'https://nitetronic.tmall.com'
      },
      {
        id: 1001,
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/10.png',
        link: 'https://beike.tmall.com/?spm=a217wi.openworkbeanchtmall.widgets.2.41e65e16sGmsjs'
      }
    ],
    h5: [
      {
        id: 1001,
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/h5-banner-05.png',
        link: 'https://beike.tmall.com/?spm=a217wi.openworkbeanchtmall.widgets.2.41e65e16sGmsjs'
      },
      {
        id: 1002,
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/h5-banner-04.png',
        link: 'https://beike.tmall.com/?spm=a217wi.openworkbeanchtmall.widgets.2.41e65e16sGmsjs'
      },
      {
        id: 1003,
        img_url:
          'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/h5-banner-03.png',
        link: 'https://nitetronic.tmall.com'
      }
    ]
  },
  ads: [
    {
      id: 1001,
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/ad-bed.jpg',
      link: 'https://nitetronic.tmall.com'
    },
    {
      id: 1002,
      img_url:
        'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/ad-chair.jpg',
      link: 'https://beike.tmall.com/?spm=a217wi.openworkbeanchtmall.widgets.2.41e65e16sGmsjs'
    }
  ],
  slogan: {
    id: 1001,
    link: 'javascript: void(0)',
    img_url:
      'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/ad-slogan.jpg'
  },
  videos: [
    {
      link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/101.mp4',
      img_url: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/product-chair.png'
    },
    {
      link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/103.mp4',
      img_url: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/product-snore.png'
    },
    {
      link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/102.mp4',
      img_url: require('../../assets/images/logo.png')
    }
  ]
}
