const footerList = [
  {
    id: 0,
    label: '智能座椅',
    list: [
      {
        label: '智能托腰办公椅',
        path: { path: '/product', query: { id: 'chair' } }
      },
      {
        label: '智能学习椅',
        path: { path: '/product', query: { id: 'chair' } }
      }
    ]
  },
  {
    id: 1,
    label: '智慧睡眠',
    list: [
      {
        label: '智能助眠床垫',
        path: { path: '/product', query: { id: 'bed' } }
      },
      {
        label: '智能小件系列',
        path: { path: '/product', query: { id: 'bed' } }
      }
    ]
  },
  {
    id: 2,
    label: '服务支持',
    list: [
      {
        label: '使用教程',
        path: '/services'
      },
      {
        label: '产品手册',
        path: '/services'
      },
      {
        label: '常规问题',
        path: '/services'
      }
    ]
  },
  {
    id: 3,
    label: '关于贝氪',
    list: [
      {
        label: '企业介绍',
        path: { path: '/about', query: { id: 0 } }
      },
      {
        label: '企业文化',
        path: { path: '/about', query: { id: 1 } }
      },
      {
        label: '发展历程',
        path: { path: '/about', query: { id: 2 } }
      },
      {
        label: '联系我们',
        path: { path: '/about', query: { id: 3 } }
      }
    ]
  }
]

const iconsLink = [
  {
    icon: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/icon-weixin.png',
    qr_img:
      'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/qr-weixin.jpg'
  },
  {
    icon: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/icon-dy.png',
    qr_img:
      'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/qr-dy.jpg'
  },
  {
    icon: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/icon-tt.png',
    qr_img:
      'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/qr-tt.jpg'
  }
]

export { footerList, iconsLink }
