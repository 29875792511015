import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/AboutView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/products/ProductsView.vue'
      )
  },
  {
    path: '/services',
    name: 'services',
    component: () =>
      import(
        /* webpackChunkName: "services" */ '../views/services/ServicesView.vue'
      )
  },
  {
    path: '/news',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: "news" */ '../views/news/NewsView.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () =>
      import(/* webpackChunkName: "news" */ '../views/news/NewsDetail.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
