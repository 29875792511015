import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { computed, watch } from 'vue';
import store from '@/store';
import router from '@/router';
import Footer from './Footer/FooterView.vue';
import Header from './Header/HeaderView.vue';
import AsideContact from '@/components/AsideContact.vue';
import menuList from '@/assets/data/menu';
export default {
  __name: 'layoutView',
  setup(__props) {
    const isMobile = computed(() => store.state.bk.global.isMobile);
    watch(() => router.currentRoute.value.fullPath, to => {
      let index = menuList.findIndex(el => {
        if (typeof el.path === 'string') return to === el.path;
        return to.includes(el.path.query.id);
      });
      store.commit('bk/global/routeChange', index);
    }, {
      immidate: true,
      deep: true
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['layout', {
          pc: !_unref(isMobile)
        }])
      }, [_createVNode(Header), _createVNode(_component_router_view), _renderSlot(_ctx.$slots, "default"), _createVNode(Footer), !_unref(isMobile) ? (_openBlock(), _createBlock(AsideContact, {
        key: 0
      })) : _createCommentVNode("", true)], 2);
    };
  }
};