import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onBeforeUnmount, onMounted } from '@vue/runtime-core';
import Layout from '@/layout/layoutView.vue';
import { isMobileHandler, langsHandler } from '@/utils/util';
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      langsHandler();
      isMobileHandler();
      window.addEventListener('resize', isMobileHandler);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', isMobileHandler);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(Layout);
    };
  }
};