export default {
  namespaced: true,
  state: {
    isMobile: false,
    langs: 'zh',
    currentRoute: 0
  },
  mutations: {
    isMobileChange(state, payload) {
      state.isMobile = payload < 450
    },
    langsChange(state, payload) {
      state.langs = payload
    },
    routeChange(state, payload) {
      state.currentRoute = payload
    }
  }
}