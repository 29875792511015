import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-33705991"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "head-menu"
};
import router from '@/router';
import store from '@/store';
import menuList from '@/assets/data/menu';
export default {
  __name: 'HeadMenu',
  props: {
    activeMenu: Number
  },
  setup(__props) {
    const props = __props;
    const selectHandler = index => {
      // store.commit('bk/global/routeChange', index)
      router.push(menuList[index].path);
    };
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_menu, {
        "default-active": `${__props.activeMenu + ''}`,
        mode: "horizontal",
        "active-text-color": "#3f85c8",
        "text-color": "#404040",
        ellipsis: false,
        onSelect: selectHandler
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuList), li => {
          return _openBlock(), _createBlock(_component_el_menu_item, {
            index: li.id + '',
            key: li.id
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(li.label), 1)]),
            _: 2
          }, 1032, ["index"]);
        }), 128))]),
        _: 1
      }, 8, ["default-active"])]);
    };
  }
};