export default [
  {
    id: 0,
    path: '/',
    label: '首页'
  },
  {
    id: 1,
    path: { path: '/product', query: { id: 'snore' } },
    label: '智能止鼾'
  },
  {
    id: 2,
    path: { path: '/product', query: { id: 'chair' } },
    label: '智能座椅'
  },
  {
    id: 3,
    path: { path: '/product', query: { id: 'bed' } },
    label: '智能床垫'
  },
  {
    id: 4,
    path: '/news',
    label: '新闻动态'
  },
  {
    id: 5,
    path: '/about',
    label: '关于贝氪'
  },
  {
    id: 6,
    path: '/services',
    label: '服务支持'
  }
]
