import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-21df30a1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "news-list"
};
const _hoisted_2 = {
  class: "w100 pl-20 pr-10"
};
const _hoisted_3 = {
  class: "title ellipsis lh-37 fs-16"
};
const _hoisted_4 = {
  key: 0,
  class: "text lh-26 fs-16"
};
const _hoisted_5 = {
  key: 1,
  class: "text lh fs-12 mt-10"
};
import store from '@/store';
import { computed } from 'vue';
export default {
  __name: 'NewsList',
  props: {
    newsList: Array
  },
  setup(__props) {
    const isMobile = computed(() => store.state.bk.global.isMobile);
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        class: _normalizeClass({
          'px-10': !_unref(isMobile)
        })
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.newsList, li => {
          return _openBlock(), _createBlock(_component_el_col, {
            class: "item px-10 mb-20",
            xs: 24,
            sm: 12,
            key: li.id
          }, {
            default: _withCtx(() => [_createVNode(_component_router_link, {
              class: "flex w100 hidden",
              to: {
                path: li.link,
                query: {
                  id: li.id
                }
              },
              style: {
                "height": "6rem"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_row, {
                class: "w100"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 9
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_image, {
                    class: "h100",
                    src: li.img_url,
                    fit: "cover"
                  }, null, 8, ["src"])]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 15
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(li.title), 1), li.author ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(li.author), 1)) : _createCommentVNode("", true), li.date ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(li.date), 1)) : _createCommentVNode("", true)])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1032, ["to"])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["class"])]);
    };
  }
};